export const firebaseConfig = {
    "projectId": "guidde-dev4",
    "appId": "1:84342788775:web:1f38dc141baf77ee108182",
    "databaseURL": "https://guidde-dev4-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-dev4.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyAGn0yjkDg3F69-2xS8IKJ5nJ6T4_FCT2U",
    "authDomain": "dev4.guidde.com",
    "messagingSenderId": "84342788775",
    "environment": "guidde-dev4"
}

export const stripeKey =
'None'
export const stiggClientKey =
'client-9ab26a72-185a-4db7-ad59-59fd52860beb:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const facebookAppId = '3155065591456766'
export const celloConfig = {
    script: 'https://assets.sandbox.cello.so/app/latest/cello.js',
    attributionScript: 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js',
    productId: 'stage-app.guidde.com'
}

export const STIGG_CLIENT_KEY =
'client-9ab26a72-185a-4db7-ad59-59fd52860beb:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const tapfiliateAccountId =
'47910-93439a'